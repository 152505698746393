import type { LoaderFunctionArgs, MetaFunction, SerializeFrom } from '@remix-run/node';
import type { ShouldRevalidateFunctionArgs} from '@remix-run/react';

import OpenLibraryKeywords from '#app/components/desktop/open-library-keywords';
import ScrollContainer from '#app/components/scroll-container';
import { Button } from '#app/components/ui/button/button';
import { Icon } from '#app/components/ui/icon';
import { getUserId } from '#app/utils/auth.server';
import { KeywordServer } from '#app/utils/keyword/keyword.server';
import { logger } from '#app/utils/logger';
import { cn } from '#app/utils/misc';
import { track } from '#app/utils/track';
import { json, redirect } from '@remix-run/node';
import { Outlet, useLoaderData, useNavigate } from '@remix-run/react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { match } from 'ts-pattern';

import LogoImage from '../../../other/png/logo.png';

export const meta: MetaFunction = ({
  location
}) => {
  return [
    { 
      content : `https://bookcovery.com${location.pathname}${location.search}`,
      property: 'og:url',
    },
    { title: '북커버리' },
    {
      content : '북커버리',
      property: 'og:title',
    },
    {
      content : '요즘 일하는 사람들이 책을 찾는 새로운 방법',
      property: 'description',
    },
    {
      content : 'https://pub-1252dd48f2ce47d69b33c3df0beff5e0.r2.dev/og%20image.png',
      property: 'og:image',
    },
    {
      content : '요즘 일하는 사람들이 책을 찾는 새로운 방법',
      property: 'og:description',
    }
  ];
};

export async function loader({ params, request }: LoaderFunctionArgs) {
  logger.info('open-library ------- START', 'loader');

  const userId = await getUserId(request);
  const defaultKeyword = !userId
    ? '전체'
    : '추천';

  if(!params.keyword) {
    return redirect(`${encodeURIComponent(defaultKeyword)}?sort=latest`);
  }

  const url = new URL(request.url);
  const sort = (url.searchParams.get('sort') ?? 'latest')as unknown as 'latest' | 'recommend';
  
  const replacedKeyword = (params.keyword ?? defaultKeyword).replaceAll('-', '/');
  const keyword = await KeywordServer.getKeywordByStringAndUserId(replacedKeyword, userId);
  logger.info(`${keyword.name} open-library ------- END`, 'loader');

  return json({
    isLogged: !!userId,
    keyword,
    sort
  });
}

export function shouldRevalidate(props: ShouldRevalidateFunctionArgs) {
  if (props.formAction === '/action-book') {
    return false;
  }
  return true;
}

export type OpenLibraryContext = { library: string } & SerializeFrom<typeof loader>;
const OpenLibrary = () => {
  const loadData = useLoaderData<typeof loader>();

  React.useEffect(() => {
    void track('view_open_library', {
      keyword: loadData.keyword.name,
      sort   : loadData.sort,
    });
  }, [loadData.sort, loadData.keyword]);



  return (
    <ScrollContainer>
      <div className={
        cn(
          'grid overflow-auto scrollbar-hide',
          'xs:grid-flow-row xs:content-start xs:h-[100dvh]',
          'sm:grid-rows-[auto_1fr] sm:pb-40 sm:h-[calc(100dvh-1px)]',
        )
      }>
        {
          isMobile && 
          <header className='py-2 grid grid-flow-col justify-start gap-1.5' data-umami-event="click_logo">
            {<img alt='북커버리' className='h-8 w-8' src={LogoImage} />}
            <Icon className='h-6 m-1 w-32' name='bookcovery' />
          </header>
        }
        <section className={
          cn(
            'xs:py-4 xs:sticky xs:top-[-1px] xs:z-[50] xs:bg-white',
            'sm:pt-10 sm:pb-5 sm:static sm:top-0 sm:z-0',
            'xs:px-4 sm:px-[3.75rem]'
          )
        }>
          {
            match(!isMobile)
              .with(true, () => (
                <h1 className='text-title-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>오픈서재</h1>
              ))
              .with(false, () => (
                <h1 className='text-title-1-bold-mobile xs:font-sfProBold sm:font-pretendardBold'>오픈서재</h1>
              ))
              .exhaustive()
          }
        </section>
        <div className='grid grid-flow-row content-start items-center'>
          {loadData.isLogged && <OpenLibraryKeywords className='xs:px-4 sm:px-[3.75rem]' selectedKeyword={loadData.keyword} sort={loadData.sort} />}
          <section className='xs:px-4 sm:px-[3.75rem]'>
            <Outlet />
          </section>
          {isMobile && !loadData.isLogged && (
            <StartBookcovery />
          )}
        </div>
      </div>
    </ScrollContainer>
  );
};

const StartBookcovery: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const onScroll = () => {
      console.log('is End');
    };
    window.addEventListener('scrollend', onScroll);
    return () => {
      window.removeEventListener('scrollend', onScroll);
    };
  }, []);


  return (
    <div className='mx-[-1rem] absolute bottom-0 w-full'>
      <div className='relative grid justify-center bg-start-bookcovery-gradient'>
        <Button
          onClick={() => {
            navigate('/signup');
          } }
          rounded={'full'}
          size={'large'}
          type="button"
          variant={'primary'}
        >
          <div className='grid grid-flow-col gap-1.5 text-label-2-bold xs:font-sfProBold items-center'>
          북커버리 시작하기
          </div>
        </Button>
      </div>
      <div className='relative h-[2.125rem] bg-white' />
    </div>
  );
};

export default OpenLibrary;