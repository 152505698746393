import { FollowKeyword, Keyword } from "@prisma/client";
import { SerializeFrom } from "@remix-run/node";
import { DateTime } from "luxon";

export const recommendDefaultKeywords: Array<SerializeFrom<FollowKeyword & { keyword: Keyword }>> = [
  {
    createdAt: DateTime.now().toString(),
    keyword  : {
      isOpen     : true,
      isSpecific : true,
      createdAt : DateTime.now().toString(),
      groupName : '',
      id        : 998,
      name      : '추천',
      searchName: '추천',
      updatedAt : DateTime.now().toString(),
    },
    keywordId: 998,
    updatedAt: DateTime.now().toString(),
    userId   : 0,
  },
  {
    createdAt: DateTime.now().toString(),
    keyword  : {
      isOpen     : true,
      isSpecific : true,
      createdAt : DateTime.now().toString(),
      groupName : '',
      id        : 997,
      name      : '전체',
      searchName: '전체',
      updatedAt : DateTime.now().toString(),
    },
    keywordId: 997,
    updatedAt: DateTime.now().toString(),
    userId   : 0,
  }
]