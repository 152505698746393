import type { PropsWithChildren } from 'react';

import React from 'react';

interface Props {

}
const ScrollContainer:React.FC<PropsWithChildren<Props>> = ({
  children
}) => {

  const onScroll = () => {
  };

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);


  return (
    <div>
      {children}
    </div>
  );
};

export default ScrollContainer;
