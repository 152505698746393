import type { Keyword } from '@prisma/client';
import type { SerializeFrom } from '@remix-run/node';

import { recommendDefaultKeywords } from '#app/utils/keyword/follow-keyword';
import { cn } from '#app/utils/misc';
import { useOptionalUser } from '#app/utils/user';
import { Form, NavLink, useSearchParams } from '@remix-run/react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { match } from 'ts-pattern';

import { TextButton } from '../ui/button/text-button';
import Chip from '../ui/chip/chip';
import { Icon } from '../ui/icon';
import ScrollKeywords from './scroll-keywords';

interface Props {
  className?: string;
  onChipClick?: (keyword: string) => void;
  selectedKeyword: SerializeFrom<Keyword>;
  sort: 'latest' | 'recommend';
}
const OpenLibraryKeywords
: React.FC<Props> = ({ className, selectedKeyword, sort }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useOptionalUser();
  return (
    <>
      {
        (
          <div 
            className={
              cn(
                'flex gap-3 items-center overflow-auto bg-white z-[50]',
                'xs:sticky xs:py-1.5 xs:top-[60px]',
                'sm:sticky sm:py-5 sm:top-[-1px]',
                'xs:flex-row-reverse sm:flex-row',
                className ?? ''
              )
            }
          >
            <Form className='whitespace-nowrap'>
              <div 
                onClick={() => {
                  searchParams.set('sort', sort === 'latest' ? 'recommend' : 'latest');
                  void setSearchParams(searchParams);
                }}
              >
                {
                  match(isMobile)
                    .with(true, () => (
                      <TextButton className='grid grid-flow-col gap-0.5' key='sort' name='sort' type='button'>
                        <Icon className='text-gray-60' name='regular-converter' />
                        {sort === 'latest' ? '최신순' : '추천순'}
                      </TextButton>
                    ))
                    .with(false, () => (
                      <Chip
                        key={'sort'}
                        name='sort'
                        preFixNode={
                          <Icon className='rotate-90' name='regular-converter' />
                        }
                        size='small'
                        value={sort}
                        variant={'gray'}
                      >
                        {sort === 'latest' ? '최신순' : '추천순'}
                      </Chip>      
                    ))
                    .otherwise(() => null)
                }
              </div>
            </Form>
            {
              user && 'followKeywords' in user && (
                <>
                  {!isMobile && <div className='h-6 w-[1px] border-solid border-gray-20 border-[1px]'/>}
                  <ScrollKeywords>
                    <div className='grid gap-2 grid-flow-col'>
                      {
                        recommendDefaultKeywords.concat(user.followKeywords ?? []).map((value) => (
                          <NavLink
                            key={value.keyword.name}
                            reloadDocument
                            to={`/open-library/${value.keyword.name.replaceAll('/', '-')}?sort=${sort}`}
                          >
                            <Chip
                              key={value.keyword.id}
                              name='library'
                              value={value.keyword.name}
                              variant={selectedKeyword.name === value.keyword.name ? 'black' : 'gray'}
                            >
                              {value.keyword.name}
                            </Chip>
                          </NavLink>
                        ))
                      }
                    </div>
                  </ScrollKeywords>
                </>
              )
            }
          </div>
        )
      }
    </>
  );
};

export default OpenLibraryKeywords;
